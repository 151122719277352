import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { AllHtmlEntities } from 'html-entities';
import Layout from '../components/Layout';
import PageHeading from '../components/PageHeading/PageHeading';
import styles from './page.module.css';

export const PageTemplate = ({ title, content }) => {
  const newContent = content;
  // .replace(
  //   new RegExp('/wp-content/', 'g'),
  //   'https://wp.veym.net/wp-content/'
  // );

  return (
    <section className="section section--gradient">
      <PageHeading>{new AllHtmlEntities().decode(title)}</PageHeading>
      <Container>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>
              {new AllHtmlEntities().decode(title)}
            </BreadcrumbItem>
          </Breadcrumb>
        </Container>

        <div className="section">
          <Container>
            <div
              className={styles.wordpressContent}
              dangerouslySetInnerHTML={{ __html: newContent }}
            />
          </Container>
        </div>
      </Container>
    </section>
  );
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

const Page = ({ data }) => {
  const { wpPage: page } = data;

  return (
    <Layout>
      <PageTemplate title={page.title} content={page.content} />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`;
